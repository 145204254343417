import React from "react"
import Utils from "../utils/node.utils"

export function createOverlay(contentMap) {
  return contentMap.overlay.value
}

export function createContentList(contentMap, commonContentMap, language) {
  if (!language) {
    language = "en"
  }

  return [
    {
      markdown: contentMap.markdown.value,
    },
    {
      header: "",
      component: (
        <div className="text-center">
          <div
            className="btn btn-dark"
            onClick={() => {
              Utils.DisplayContactNotification(contentMap, commonContentMap)
            }}
          >
            {contentMap.open_form.value}
          </div>
        </div>
      ),
    },
    {
      markdown: contentMap.markdown_bulk.value,
    },
    {
      header: "",
      component: (
        <div className="text-center mb-4">
          <div
            className="btn btn-dark"
            onClick={() => {
              Utils.DisplayBulkNotification(contentMap, commonContentMap)
            }}
          >
            {contentMap.open_form_bulk.value}
          </div>
        </div>
      ),
    },
  ]
}
